<template>
  <section class="showcase-countries ">
    <div class="wrapper">
      <div class="showcase-countries__title">
        <h3>{{ $t('country.title') }}</h3>
        <AppSearch
          :placeholder="t('country.placeholder')"
          :search-list="countriesList"
          search-icon
          @onInput="search"
        />
      </div>
      <div class="showcase-countries__sort-bar">
        <SortBar
            @selectSortParam="setSortParam"
        />
      </div>
      <div class="countries-list scroll-customize">
        <template v-if="resultList && resultList.length">
          <div
            v-for="country in resultList"
            :key="country.id"
            :class="{'active':country.id === activeCountry?.id}"
            class="country"

            @click="setCurrentCountry(country)"
          >
            <div class="service-wrapper">
              <div class="left">
                <img
                  :src="`${config.baseUrl}${country.img_path}`"
                  alt="flag"
                >
                <span>{{ country.title }}</span>
              </div>
              <div class="right">
                <div class="amount">{{ country.phones }} {{ $t('pcs') }}</div>
                <div class="price">{{ country.price }} &#8381;</div>
                <div v-if="!loadingCountriesIds?.includes(country.id)" class="buy-btn btn primary">{{ $t('button.buy') }}</div>
				        <div v-if="loadingCountriesIds?.includes(country.id)" class="buy-btn btn primary"><default-loader /></div>
              </div>
            </div>
            <div class="separator" />
          </div>
        </template>

        <div
          v-else
          class="h3"
        >
          {{ $t('empty') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { GetCountriesApp } from "~/helpers/parsers/CountriesDTO";
import AppSearch from "~/components/common/AppSearch.vue";
import type { AppSearchEmitType } from "~/types/commons";
import type { CountriesListWithPriceType } from '~/server/routes/api-front/prices/by-service/[service]';
import DefaultLoader from '~/components/widgets/loaders/DefaultLoader.vue';
const { t, locale } = useI18n();
const config = useRuntimeConfig().public;

const props = defineProps<{
	countriesList: CountriesListWithPriceType[];
	activeCountry?: CountriesListWithPriceType | null;
	loadingCountriesIds?: number[];
}>();

const emits = defineEmits<{
	selectCountry: [CountriesListWithPriceType],
  selectSortParam: [name: string]
}>();

const SortBar = defineAsyncComponent(() => import('~/components/widgets/sort-components/SortBar.vue'));

const countryList = ref<CountriesListWithPriceType[]>([]);
const isInputClear = ref<boolean>(true);

function setCurrentCountry (country: CountriesListWithPriceType) {
  emits('selectCountry', country);
}

function search(res: AppSearchEmitType<CountriesListWithPriceType>) {
  countryList.value = res.searchList;
  isInputClear.value = res.isInputClear;
}

const resultList: any = computed(() => {
  if (isInputClear.value) {
    return props.countriesList.map((country) => {
      return {
        ...country,
        title: country?.langs?.[String(locale.value || 'en')] || country.title
      }
    });
  }

  return countryList.value.map((country) => {
    return {
      ...country,
      title: country?.langs?.[String(locale.value || 'en')] || country.title
    }
  });
});

function setSortParam(name: string) {
  emits('selectSortParam', String(name));
}
</script>

<style scoped lang="scss">
.showcase-countries {
	background: var(--white);
	border-radius: var(--main-border-radius);
	padding: 30px;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
  backdrop-filter: blur(53px);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 205px 58px 0px rgba(0, 0, 0, 0) inset;

  & .h3 {
    width: 100%;
    display: block;
    margin-top: 20px;
    text-align: center;
  }

	& .wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		gap: 30px;

    .showcase-countries__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      h3 {
        margin: 0;
        color: var(--black);
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.3px;
      }
    }

    :deep(input) {
      padding: 6px 5px 7px 5px;
      border: none;
      border-radius: 0;
      border-bottom: 0.5px solid var(--grey-dark);
      font-style: normal;
      font-size: 16px;

      &::placeholder {
        font-style: normal;
        font-size: 16px;
      }

      @media screen and (max-width: 1024px) {
        padding: 5px 20px 7px 4px;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(217, 217, 217, 0.1);
        font-size: 15px;

        &::placeholder {
          color: #454545;
          font-size: 14px;
          font-style: italic;
          font-weight: 100;
          letter-spacing: -0.15px;
        }
      }
    }
    .showcase-countries__sort-bar {
      padding-right: 10px;
    }
	}

	& .countries-list {
		display: flex;
		flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
	}

	& .country {
		width: 100%;

		&.active {
			& img {
				border: 1px solid var(--primary);
			}

			& > span {
				font-weight: 600;
			}
		}

		& .left {
			display: flex;
			align-items: center;
			gap: 8px;

			& > img {
				width: 40px;
				min-width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
				border: 1px solid transparent;
			}

			& > span {
				font-size: 16px;
				font-weight: 500;
				line-height: normal;
			}

      @media screen and (max-width: 860px) {
        & {
          gap: 5px;
        }
        & > span {
          font-size: 14px;
        }
      }
      @media screen and (max-width: 470px) {
        flex-direction: column;
        align-items: flex-start;

        & > span {
          word-break: break-word
        }
      }
		}
	}

	& .service-wrapper {
		display: flex;
		align-items: center;
		gap: 9px;
		width: 100%;
		cursor: pointer;
		justify-content: space-between;
    padding: 14px;
    transition: var(--transition);

		&:hover{
      border-radius: 12px;
      background: rgba(215, 235, 255, 0.5);
		}

		@media screen and (max-width: 1200px) {
			flex-direction: column;
		}

		@media screen and (max-width: 1024px) {
			flex-direction: row;
    }
	}

	& .right {
		display: flex;
		gap: 40px;
		align-items: center;

		& .buy-btn {
			height: 30px;
			min-height: 30px;
			font-size: 15px;
			line-height: 100%;
			border-radius: 10px;
      background-color: var(--primary-90);
		}

		& .amount { // хочу уменьшить
			color: var(--primary);
			font-size: 13px;
			font-weight: 400;
			line-height: normal;
		}

		& .price {
			background: #daedff;
			color: var(--primary);
			padding: 4px 14px;
			border-radius: 6px;
      white-space: nowrap;
		}
    @media screen and (max-width: 1024px) {
			gap: 10px
		}
    @media screen and (max-width: 440px) {
      gap: 5px;
      & .buy-btn {
        font-size: 12px;
      }
      & .amount {
        font-size: 11px;
      }
      & .price {
        font-size: 12px;
      }
    }
	}

	& .separator {
		width: 100%;
		height: 1px;
		background: var(--primary-20);
	}

	& .h2 {
		text-align: center;
		margin: 0 auto;
	}
}

@media screen and (max-width: 550px) {
  .showcase-countries {
    padding: 20px 15px;
  }
}

@media screen and (max-width: 420px) {
  .showcase-countries {
    padding: 20px 5px;
  }
}

</style>
