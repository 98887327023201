<template>
  <section class="showcase-services">
    <div 
      v-if="!isServicesListLoading"
      class="wrapper"
    >
      <div class="showcase-services__title">
        <h3>{{ $t('service.title') }}</h3>
        <AppSearch
          :placeholder="t('service.placeholder')"
          :search-list="servicesList || []"
          search-icon
          @onInput="search"
        />
      </div>
        <template v-if="resultList && resultList.length">
          <div class="service-list scroll-customize">
            <div
              v-for="service in resultList"
              :key="service.id"
              :class="{'active':service.id === activeService?.id}"
              class="service"

              @click="setCurrentService(service)"
            >
              <img
                :src="`${config.baseUrl}${service.img_path}`"
                alt="flag"
              >
              <span>{{ service.title }}</span>
            </div>
          </div>
        </template>
        <div
          v-else
          class="h3"
        >
          {{ $t('empty') }}
        </div>
    </div>
    <div
      v-else
      class="lading-showcase"
    >
      {{ $t('loading') }}
    </div>
  </section>
</template>

<script setup lang="ts">
import type { GetServicesApp } from '~/helpers/parsers/ServicesDTO';
import type { ServiceListWithPriceType } from '~/server/routes/api-front/prices/by-country/[country]';
import AppSearch from '~/components/common/AppSearch.vue';
import type { AppSearchEmitType } from '~/types/commons';
const { t } = useI18n();
const config = useRuntimeConfig().public;

const props = defineProps<{
	servicesList: GetServicesApp[] | null;
	activeService?: GetServicesApp | null;
	isServicesListLoading: boolean;
}>();

const emits = defineEmits<{
	selectService: [GetServicesApp]
}>();

function setCurrentService (service: GetServicesApp) {
  emits('selectService', service);
}

const serviceList = ref<GetServicesApp[]>([]);
const isInputClear = ref<boolean>(true);

function search (res: AppSearchEmitType<GetServicesApp>) {
  serviceList.value = res.searchList;
  isInputClear.value = res.isInputClear;
}
const resultList = computed(() => {
  if (isInputClear.value) {
    return props.servicesList
  }

  return serviceList.value;
});
</script>

<style scoped lang="scss">

.showcase-services {
	background: var(--white);
  border-radius: var(--main-border-radius) 0 0 var(--main-border-radius);
	padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(0, 0, 0, 0.09) inset;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
  margin: 9px -10px 5px 0;

  & .h3 {
    width: 100% !important;
    display: block !important;
    margin-top: 20px !important;
    text-align: center !important;
    
    @media screen and (max-width: 640px) {

    }
  }

	& .wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
    gap: 30px;

    .showcase-services__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      h3 {
        margin: 0;
        color: var(--black);
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.3px;
      }
    }

    :deep(input) {
			padding: 6px 5px 7px 5px;
			border: none;
			border-radius: 0;
			border-bottom: 0.5px solid var(--grey-dark);
			font-style: normal;
			font-size: 16px;

			&::placeholder {
				font-style: normal;
				font-size: 16px;
			}

			@media screen and (max-width: 1024px) {
				padding: 5px 20px 7px 4px;
				border-radius: 10px;
				border: 1px solid rgba(255, 255, 255, 0.10);
				background: rgba(217, 217, 217, 0.10);
				font-size: 15px;

				&::placeholder {
					color: #454545;
					font-size: 14px;
					font-style: italic;
					font-weight: 100;
					letter-spacing: -0.15px;
				}
			}
		}
	}

	& .service-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: -10px;
		margin-left: -10px;
		overflow-y: auto;
		overflow-x: hidden;
    padding-right: 8px;

		& > div {
			margin-top: 10px;
			margin-left: 10px;
			width: calc(33.3333% - 10px);
		}
    @media screen and (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 68px));
      margin: 0;
      gap: 15px;
      justify-content: space-evenly;

      > div {
				justify-content: center;
        margin: 0;
        width: 100%;
        background: none;
        border: none;

        img {
          width: 48px;
          height: 50px;
        }

        span {
          display: none;
        }

				&:hover {
					border-radius: 12px;
					background: rgba(215, 235, 255, 0.70);
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
				}
      }
    }
	}

	& .service {
		display: flex;
		align-items: center;
		gap: 9px;
		cursor: pointer;
		padding: 4px 8px;
		border: 1px solid transparent;
		border-radius: 4px;
    transition: var(--transition);

    &:hover {
      background: var(--primary-20);
      border: 1px solid var(--primary);
    }

		&.active {
			border: 1px solid var(--primary);
      background: var(--primary-20);

      & > span {
				font-weight: 600;
			}
		}

		& > img {
			width: 34px;
			min-width: 34px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 6px;
			border: 1px solid transparent;
			object-fit: contain;
		}

		& > span {
			font-size: 16px;
			font-weight: 500;
			line-height: normal;
		}
	}
}
@media screen and (max-width: 1024px) {
  .showcase-services {
    margin: 0;
  }
}

@media screen and (max-width: 550px) {
  .showcase-services {
    padding: 20px 15px;
  }
}

@media screen and (max-width: 420px) {
  .showcase-services {
    padding: 20px 5px;
  }
}
</style>
